/* TableComponent.module.css */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  position: relative;
}

.table tr {
  position: relative;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: baseline;
}

.table th {
  background-color: none;
}

/* Add hover effect for better user experience */
.table tr:hover {
  background-color: none;
}
