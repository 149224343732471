@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai+Looped:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
html,
body {
  font-family: "Noto Sans Thai Looped", sans-serif !important;
}
.ant-layout-sider {
  max-width: max-content !important;
  min-width: max-content !important;
  width: max-content !important;
}

.ck-editor__editable {
  min-height: 400px;
}

.ant-form {
  max-width: 800px;
  margin: 0 auto;
}

.ant-select-selector {
  /* height: 32px !important; */
}

/* .upload-one .ant-upload {
  width: 100% !important;
} */
